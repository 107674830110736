header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9; }
  header .header {
    height: 170px;
    width: 100%;
    background-color: #000; }
    @media only screen and (max-width: 1023px) {
      header .header {
        height: 100px; } }
    header .header .row {
      align-items: center; }
    header .header .telephone .phone {
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      margin-left: 10px; }
    @media only screen and (max-width: 1023px) {
      header .header .telephone {
        display: none; } }
    @media only screen and (max-width: 1023px) {
      header .header .logo svg {
        height: 100px;
        width: 182px;
        position: fixed;
        top: 0;
        left: 50%;
        margin-left: -91px; } }
    header .header .social {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media only screen and (max-width: 1023px) {
        header .header .social {
          display: none; } }
      header .header .social .text {
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        margin-right: 10px; }
      header .header .social .isvg {
        margin: 0 5px; }
    header .header .menu {
      display: none; }
      header .header .menu button {
        background-color: transparent;
        border: 0;
        outline: 0;
        float: right; }
      @media only screen and (max-width: 1023px) {
        header .header .menu {
          display: block;
          position: fixed;
          right: 0px;
          top: 50px;
          margin-top: -15px; } }
  header .navigation {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 1023px) {
      header .navigation {
        display: none; } }
    header .navigation li {
      padding: 30px 0;
      font-size: 16px;
      font-weight: 700; }
      header .navigation li a {
        position: relative;
        color: #000;
        text-decoration: none; }
        header .navigation li a::after {
          content: ' ';
          position: absolute;
          bottom: -20px;
          left: 50%;
          margin-left: -4px;
          height: 8px;
          width: 8px;
          border-radius: 4px;
          background-color: #C1A35E;
          display: none; }
      header .navigation li:hover a {
        color: #C1A35E;
        /*&::after{
                        display: block;
                    }*/ }
    header .navigation .active a {
      color: #C1A35E; }
  header .navigation-container {
    position: relative; }
    header .navigation-container .dot {
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background-color: #C1A35E;
      position: absolute;
      top: 50%;
      margin-top: 20px; }
      @media only screen and (max-width: 1023px) {
        header .navigation-container .dot {
          display: none; } }
  header .mobile-close {
    display: none; }
    @media only screen and (max-width: 1023px) {
      header .mobile-close {
        display: block;
        background-color: transparent;
        border: 0;
        outline: none;
        position: fixed;
        top: 32px;
        right: 32px;
        -webkit-animation-name: rotate-menu;
        /* Safari 4.0 - 8.0 */
        -webkit-animation-duration: 1s;
        /* Safari 4.0 - 8.0 */
        animation-name: rotate-menu;
        animation-duration: 1s;
        /* Safari 4.0 - 8.0 */
        /* Standard syntax */ }
      @-webkit-keyframes rotate-menu {
        from {
          transform: rotate(0deg); }
        to {
          transform: rotate(360deg); } }
      @keyframes rotate-menu {
        from {
          transform: rotate(0deg); }
        to {
          transform: rotate(360deg); } } }
  header .mobile-social {
    display: none; }
    @media only screen and (max-width: 1023px) {
      header .mobile-social {
        display: block;
        background-color: transparent;
        border: 0;
        outline: none;
        position: fixed;
        bottom: 32px;
        right: 32px; }
        header .mobile-social a {
          margin: 0 5px; } }
  header .navigation-mobile {
    position: fixed;
    top: 0;
    left: 20%;
    width: 80%;
    min-height: 100vh;
    background-color: #000;
    display: none;
    padding: 64px;
    -webkit-animation-name: mobile-menu;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: mobile-menu;
    animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }

@keyframes mobile-menu {
  from {
    left: 100%; }
  to {
    left: 20%; } }
    @media only screen and (max-width: 1023px) {
      header .navigation-mobile {
        display: block; } }
    header .navigation-mobile li {
      padding: 16px 0; }
      header .navigation-mobile li a {
        color: #FFFFFF; }
        header .navigation-mobile li a::after {
          display: none; }
    header .navigation-mobile .active a::after {
      display: none; }

@media only screen and (min-width: 1023px) {
  .sticky {
    position: fixed;
    top: 37px;
    left: 0;
    width: 100%;
    z-index: 9; }
    .sticky .header {
      display: none; }
    .sticky .container {
      background-color: #FFFFFF;
      -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
      border-radius: 91px;
      padding: 0 30px; } }
    @media only screen and (min-width: 1023px) and (max-width: 1023px) {
      .sticky .container {
        all: unset; } }

@media only screen and (min-width: 1023px) {
    .sticky .navigation {
      margin-bottom: 0; }
      .sticky .navigation li {
        padding: 23px 0; }
    .sticky .navigation-mobile {
      position: fixed;
      top: 0;
      left: 20%;
      width: 80%;
      min-height: 100vh;
      background-color: #000;
      display: block;
      padding: 64px; }
      .sticky .navigation-mobile li {
        cursor: pointer;
        padding: 16px 0; }
        .sticky .navigation-mobile li a {
          color: #FFFFFF; }
          .sticky .navigation-mobile li a::after {
            display: none; }
      .sticky .navigation-mobile .active a::after {
        display: none; } }

.section-1 h3 {
  font-size: 64px;
  font-weight: 900;
  margin-bottom: 25px;
  margin-top: 20px;
  color: #151F4C; }
  @media only screen and (max-width: 1023px) {
    .section-1 h3 {
      font-size: 48px; } }

.section-1 h6 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  color: #C1A35E;
  max-width: 432px; }
  @media only screen and (max-width: 767px) {
    .section-1 h6 {
      margin-bottom: 20px; } }

.section-1 ul {
  list-style: none;
  padding: 0; }
  .section-1 ul li {
    position: relative;
    padding: 8px 0 8px 36px;
    font-size: 18px;
    font-weight: 400;
    color: #000; }
    .section-1 ul li::before {
      content: ' ';
      display: block;
      background-image: url(./assets/check.svg);
      width: 24px;
      height: 19px;
      position: absolute;
      top: 16px;
      left: 0; }

.section-1 .animation-container {
  width: 670px; }
  @media only screen and (max-width: 767px) {
    .section-1 .animation-container {
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .section-1 .animation-container {
      width: 100%; } }

.section-1 img {
  width: 670px; }
  @media only screen and (max-width: 767px) {
    .section-1 img {
      width: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .section-1 img {
      width: 100%; } }

.section-1 .scroll-down {
  margin-top: -80px; }
  @media only screen and (max-width: 767px) {
    .section-1 .scroll-down {
      margin-top: 0; } }
  .section-1 .scroll-down a {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 81px; }
    @media only screen and (max-width: 767px) {
      .section-1 .scroll-down a {
        margin-bottom: 20px; } }
    .section-1 .scroll-down a span {
      font-size: 10px;
      font-weight: 700;
      margin-top: 5px;
      color: #151F4C;
      max-width: 60px;
      text-align: center; }

.section-2 h3 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 34px;
  margin-top: 24px;
  color: #151F4C; }
  @media only screen and (max-width: 1023px) {
    .section-2 h3 {
      font-size: 36px; } }

.section-2 h6 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  color: #C1A35E;
  max-width: 432px; }

.section-2 p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
  color: #000; }

.section-2 ul {
  list-style: none;
  padding: 0; }
  .section-2 ul li {
    position: relative;
    padding: 8px 0 8px 36px;
    font-size: 18px;
    font-weight: 400;
    color: #000; }
    .section-2 ul li::before {
      content: ' ';
      display: block;
      background-image: url(./assets/check.svg);
      width: 24px;
      height: 19px;
      position: absolute;
      top: 16px;
      left: 0; }

.section-2 .animation-container {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 767px) {
    .section-2 .animation-container {
      margin-bottom: 0px; } }

.section-2 img {
  width: 100%;
  margin-bottom: 89px; }
  @media only screen and (max-width: 767px) {
    .section-2 img {
      margin-bottom: 0px; } }

.section-3 {
  margin-top: 81px; }
  .section-3 h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 26px;
    color: #151F4C; }
  .section-3 h6 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    color: #C1A35E;
    max-width: 432px; }
  .section-3 p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #000; }
  .section-3 ul {
    list-style: none;
    padding: 0; }
    .section-3 ul li {
      position: relative;
      padding: 9px 0 9px 18px;
      font-size: 16px;
      font-weight: 400;
      color: #000; }
      .section-3 ul li::before {
        content: ' ';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        position: absolute;
        background-color: #C1A35E;
        top: 16px;
        left: 0; }
  .section-3 .animation-container {
    width: 100%;
    margin-bottom: 89px; }
  .section-3 img {
    width: 100%;
    margin-bottom: 89px; }

.section-4 {
  position: relative;
  margin-top: 120px; }
  @media only screen and (max-width: 767px) {
    .section-4 {
      margin-top: 0px; }
      .section-4 .carousel {
        margin-top: 60px; } }
  .section-4 .image {
    width: 100%; }
  .section-4 button {
    background-color: transparent;
    border: 0;
    outline: none;
    margin-right: 30px;
    margin-top: 10px; }
  .section-4 .bg {
    position: absolute;
    left: 0;
    top: -200px;
    width: 39%; }
    @media only screen and (max-width: 767px) {
      .section-4 .bg {
        top: 0;
        width: 80%; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-4 .bg {
        top: -40px;
        width: 37%; } }
  .section-4 h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
    color: #151F4C; }
  .section-4 h6 {
    font-size: 20px;
    font-weight: 700;
    color: #C1A35E;
    margin-bottom: 28px; }
  .section-4 p {
    font-size: 18px;
    font-weight: 400;
    color: #000; }
  .section-4 .carousel-item {
    min-height: 350px; }

.section-5 {
  margin-top: 168px; }
  @media only screen and (max-width: 767px) {
    .section-5 {
      margin-top: 40px; } }
  .section-5 h3 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 44px;
    margin-top: 24px;
    color: #151F4C; }
    @media only screen and (max-width: 1023px) {
      .section-5 h3 {
        font-size: 36px; } }
  .section-5 h6 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #151F4C;
    max-width: 432px; }
  .section-5 h4 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #151F4C; }
  .section-5 button {
    background-color: #C1A35E;
    outline: none;
    border: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 24px;
    border-radius: 10px; }
    .section-5 button .overlay {
      background-color: #151F4C; }
  .section-5 p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #000; }
  .section-5 ul {
    list-style: none;
    padding: 0; }
    .section-5 ul li {
      position: relative;
      padding: 8px 0 8px 36px;
      font-size: 18px;
      font-weight: 400;
      color: #000; }
      .section-5 ul li::before {
        content: ' ';
        display: block;
        background-image: url(./assets/check.svg);
        width: 24px;
        height: 19px;
        position: absolute;
        top: 16px;
        left: 0; }
  .section-5 .animation-container {
    width: 100%;
    margin-bottom: 89px; }
    @media only screen and (max-width: 1023px) {
      .section-5 .animation-container {
        margin-top: 40px; } }
  .section-5 img {
    width: 100%;
    margin-bottom: 89px; }
    @media only screen and (max-width: 1023px) {
      .section-5 img {
        margin-top: 40px; } }

.section-6 {
  position: relative;
  margin-top: 173px; }
  @media only screen and (max-width: 767px) {
    .section-6 {
      margin-top: 0; } }
  .section-6 .image {
    width: 100%; }
  .section-6 button {
    background-color: #151F4C;
    outline: none;
    border: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    padding: 16px 43px;
    border-radius: 10px;
    float: right; }
    .section-6 button .overlay {
      background-color: #C1A35E; }
    @media only screen and (max-width: 767px) {
      .section-6 button {
        margin-top: 16px; } }
  .section-6 .bg {
    position: absolute;
    right: 0;
    top: -250px;
    width: 53%; }
    @media only screen and (max-width: 767px) {
      .section-6 .bg {
        top: 250px;
        width: 90%; } }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      .section-6 .bg {
        top: -150px;
        width: 60%; } }
  .section-6 h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    color: #151F4C; }
  .section-6 h3 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 30px;
    color: #151F4C; }
  .section-6 h6 {
    font-size: 18px;
    font-weight: 700;
    color: #151F4C;
    margin-bottom: 0; }
  .section-6 p {
    font-size: 18px;
    font-weight: 400;
    color: #000; }
  .section-6 a {
    color: #151F4C;
    text-decoration: underline; }
  .section-6 textarea {
    height: 160px; }

.map {
  height: 400px;
  width: 100%; }

footer {
  margin-top: 212px; }
  @media only screen and (max-width: 767px) {
    footer {
      margin-top: 50px; } }
  footer .navigation {
    padding: 0;
    margin-top: 30px;
    padding-bottom: 16px;
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #BABBBE;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      footer .navigation {
        display: none; } }
    footer .navigation li {
      padding: 30px 0;
      font-size: 16px;
      font-weight: 700; }
      footer .navigation li a {
        position: relative;
        color: #C1A35E;
        text-decoration: none; }
      footer .navigation li:hover a {
        color: #151F4C; }
    footer .navigation .active a {
      color: #151F4C; }
  footer .copyright {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 30px 0;
    margin-bottom: 0; }
  footer .created-by {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 30px 0;
    margin-bottom: 0;
    text-align: right; }
    footer .created-by a {
      font-weight: 700;
      color: #000; }

html, body {
  min-height: 100%;
  min-height: 100vh;
  position: relative;
  width: 100%;
  width: 100vw;
  overflow-x: hidden;
  font-family: 'Barlow', sans-serif;
  background-color: #f5f5f5; }

html, body, h1, h2, h3, h4, h5, h6, p, a, span, div {
  font-family: 'Barlow', sans-serif; }

button {
  cursor: pointer; }

.input-field {
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #C1A35E;
  outline: none;
  width: 100%;
  margin-bottom: 20px; }

.required {
  border: 1px solid red; }

.wrapper {
  margin-top: 270px; }
  @media only screen and (max-width: 1023px) {
    .wrapper {
      margin-top: 130px; } }

.button {
  position: relative;
  overflow: hidden; }
  .button .overlay {
    position: absolute;
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px;
    border-radius: 50%;
    -webkit-animation-name: grow;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    animation-name: grow;
    animation-duration: 0.5s;
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }

@-webkit-keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px; } }

@keyframes grow {
  from {
    width: 0;
    height: 0;
    margin-left: 0;
    margin-top: 0; }
  to {
    width: 700px;
    height: 700px;
    margin-left: -350px;
    margin-top: -350px; } }
  .button .content {
    z-index: 1;
    position: relative; }

@keyframes animation-rotate {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(15deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes animation-rotate1 {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(-15deg); }
  100% {
    transform: rotate(0deg); } }

@media only screen and (max-width: 1400px) {
  .left-animation {
    display: none; } }

.left-animation img:nth-child(2n) {
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

.left-animation img:nth-child(2n+1) {
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

.left-animation .animation-1 {
  position: absolute;
  top: 290px;
  left: 118px; }

.left-animation .animation-2 {
  position: absolute;
  top: 916px;
  left: 205px; }

.left-animation .animation-3 {
  position: absolute;
  top: 1149px;
  left: 172px; }

.left-animation .animation-4 {
  position: absolute;
  top: 2224px;
  left: 102px; }

.left-animation .animation-5 {
  position: absolute;
  top: 3475px;
  left: 105px; }

.left-animation .animation-6 {
  position: absolute;
  top: 4085px;
  left: 202px; }

.left-animation .animation-7 {
  position: absolute;
  top: 4410px;
  left: 176px; }

.left-animation .animation-8 {
  position: absolute;
  top: 4826px;
  left: 118px; }

@media only screen and (max-width: 1400px) {
  .right-animation {
    display: none; } }

.right-animation img:nth-child(2n) {
  -webkit-animation-name: animation-rotate;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

.right-animation img:nth-child(2n+1) {
  -webkit-animation-name: animation-rotate1;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1s;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite;
  animation-name: animation-rotate1;
  animation-duration: 1s;
  animation-iteration-count: infinite; }

.right-animation .animation-1 {
  position: absolute;
  top: 1101px;
  right: 118px; }

.right-animation .animation-2 {
  position: absolute;
  top: 1326px;
  right: 205px; }

.right-animation .animation-3 {
  position: absolute;
  top: 1692px;
  right: 172px; }

.right-animation .animation-4 {
  position: absolute;
  top: 2112px;
  right: 102px; }

.right-animation .animation-5 {
  position: absolute;
  top: 2500px;
  right: 105px; }

.right-animation .animation-6 {
  position: absolute;
  top: 3052px;
  right: 202px; }

.right-animation .animation-7 {
  position: absolute;
  top: 3368px;
  right: 176px; }

.right-animation .animation-8 {
  position: absolute;
  top: 4099px;
  right: 118px; }

.right-animation .animation-9 {
  position: absolute;
  top: 5022px;
  right: 118px; }
